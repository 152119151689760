import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import * as React from 'react';
import { useCallback } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { IPromoCodeCreateData } from '@ystb/backend/dist/promoCode/promoCodeModel';
import { AccountType } from '@ystb/backend/dist/user/user';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';

export interface IPromoCodeDialogProps {
    handleCloseModal: () => void;
    showModalDialog: boolean;
    onSubmit: ({ data }: { data: IPromoCodeCreateData }) => void;
}

export const PromoCodeDialog = (props: IPromoCodeDialogProps) => {
    const { handleCloseModal, showModalDialog, onSubmit } = props;
    const [expireDate, setExpireDate] = React.useState<Dayjs>(dayjs());
    const [accountType, setAccountType] = React.useState<AccountType>(AccountType.Investor);
    const [monthCount, setMonthCount] = React.useState<string>('3');

    const expireDateChange = useCallback((newValue: any) => setExpireDate(newValue), []);
    const handleAccountTypeChange = useCallback((event: SelectChangeEvent) => {
        setAccountType(event.target.value as AccountType);
    }, []);
    const handleMonthCountChange = useCallback((event: SelectChangeEvent) => {
        setMonthCount(event.target.value);
    }, []);

    const onFormSubmit = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            onSubmit({
                data: {
                    code: formJson.code,
                    limit: formJson.limit,
                    expired_date: expireDate.toDate(),
                    account_type: accountType,
                    month_count: parseInt(monthCount),
                },
            });
            handleCloseModal();
        },
        [expireDate, accountType, monthCount]
    );

    const dialogTitle = 'Добавить промокод';
    const dialogSubmitBtn = 'Добавить';

    return (
        <Dialog
            open={showModalDialog}
            onClose={handleCloseModal}
            PaperProps={{
                component: 'form',
                onSubmit: onFormSubmit,
            }}
        >
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent
                style={{
                    minWidth: 600,
                }}
            >
                <TextField
                    autoFocus={true}
                    required
                    margin="dense"
                    id="code"
                    name="code"
                    label="Промокод"
                    type="text"
                    fullWidth
                    variant="standard"
                />
                <TextField
                    required={true}
                    margin="dense"
                    id="limit"
                    name="limit"
                    label="Лимит"
                    type="number"
                    fullWidth
                    variant="standard"
                />
                <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                    <DatePicker label="Действителен по:" value={expireDate} onChange={expireDateChange} />
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                    <InputLabel id="demo-select-small-label">Тариф</InputLabel>
                    <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={accountType}
                        label="Тариф"
                        onChange={handleAccountTypeChange}
                    >
                        <MenuItem value={AccountType.Investor}>Инвестор</MenuItem>
                        <MenuItem value={AccountType.Trader}>Трейдер</MenuItem>
                        <MenuItem value={AccountType.Speculative}>Спекулянт</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                    <InputLabel id="demo-select-small-label">Подписка на</InputLabel>
                    <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={monthCount}
                        label="Подписка на"
                        onChange={handleMonthCountChange}
                    >
                        <MenuItem value={1}>1 мес.</MenuItem>
                        <MenuItem value={2}>2 мес.</MenuItem>
                        <MenuItem value={3}>3 мес.</MenuItem>
                        <MenuItem value={6}>6 мес.</MenuItem>
                        <MenuItem value={12}>12 мес.</MenuItem>
                    </Select>
                </FormControl>
            </DialogContent>

            <DialogActions style={{ padding: '20px' }}>
                <Button onClick={handleCloseModal} color={'primary'}>
                    Отмена
                </Button>
                <Button type="submit" variant={'contained'}>
                    {dialogSubmitBtn}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
