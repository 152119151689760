import { rawPriceToNumber } from '../utils/price';

export interface IRawPrice {
    units: string;
    nano: string;
}

export interface IRawCandle {
    ticker: string;
    close: IRawPrice;
    open: IRawPrice;
    high: IRawPrice;
    low: IRawPrice;
    volume: number;
    time: Date;
}

export interface IDbCandle {
    ticker: string;
    close: number;
    open: number;
    high: number;
    low: number;
    volume: number;
    time: Date;
}

export class RawDbCandle {
    ticker: string;
    close: number;
    open: number;
    high: number;
    low: number;
    volume: number;
    time: Date;

    constructor(data: IRawCandle) {
        this.ticker = data.ticker;
        this.close = rawPriceToNumber(data.close);
        this.open = rawPriceToNumber(data.open);
        this.high = rawPriceToNumber(data.high);
        this.low = rawPriceToNumber(data.low);
        this.volume = data.volume;
        this.time = data.time;
    }
}

export class DbCandle {
    ticker: string;
    close: number;
    open: number;
    high: number;
    low: number;
    volume: number;
    time: Date;

    constructor(data: IDbCandle) {
        this.ticker = data.ticker;
        this.close = this.toFloat(data.close);
        this.open = this.toFloat(data.open);
        this.high = this.toFloat(data.high);
        this.low = this.toFloat(data.low);
        this.volume = data.volume;
        this.time = new Date(data.time);
    }

    private toFloat(value: string | number): number {
        if (typeof value === 'string') {
            value = parseFloat(value);
        }
        return value;
    }
}
