import { useCallback, useEffect, useState, memo } from 'react';
import { find, includes } from 'lodash';
import { isMobile } from 'react-device-detect';
import dayjs, { Dayjs } from 'dayjs';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers';
import { styled } from '@mui/material/styles';
import {
    Chip,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    SelectChangeEvent,
    tableCellClasses,
    TableContainer,
} from '@mui/material';

import AppState from '../AppState';
import { Copyright } from '../components/copyright';
import { NavigationMenu } from './components/NavigationMenu';
import { routing } from '../constants/navigation';

// @ts-ignore
import { ITradeIdea, TradeIdeaModel } from '@ystb/backend/dist/tradeIdea/tradeIdeaModel';
// @ts-ignore
import { TimeFrame, IdeaType } from '@ystb/backend/dist/common';
// @ts-ignore
import { getPercent } from '@ystb/backend/dist/utils/price';

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

interface ITradeResult {
    totalIdeas: number;
    countStopLose: number;
    countStopLoseWithSuccess: number;
    countTarget1: number;
    countTarget2: number;
    countTarget3: number;
    countTarget4: number;
    countTarget5: number;
    finishedIdeas: number;
    losePercents: number;
    profitPercents: number;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const allItemsKey = 'all';
const TimeFrameSelectItems = {
    [allItemsKey]: 'Все',
    [TimeFrame.OneHour]: '1 час',
    [TimeFrame.FourHour]: '4 часа',
    [TimeFrame.OneDay]: '1 день',
    [TimeFrame.OneWeek]: '1 неделя',
    [TimeFrame.OneMonth]: '1 месяц',
};

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
            },
        }),
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 800,
    },
}));

let isActivated = false;

interface ITradeItems {
    items1h: TradeIdeaModel[];
    items4h: TradeIdeaModel[];
    items1d: TradeIdeaModel[];
    items1w: TradeIdeaModel[];
    items1m: TradeIdeaModel[];
}

const DashboardRootContent = memo(() => {
    const [tradeResults, setTradeResults] = useState<ITradeResult>();
    const [moexLevel, setMoexLevel] = useState<string>('');
    const [ideaType, setIdeaType] = useState<string>('');
    const [timeFrames, setTimeFrames] = useState<string[]>([allItemsKey]);
    const [maxTarget, setMaxTarget] = useState<string>('5');
    const [oneDealPercent, setOneDealPercent] = useState<string>('6');
    const [items, setItems] = useState<ITradeItems>({
        items1h: [],
        items4h: [],
        items1d: [],
        items1w: [],
        items1m: [],
    });
    const [dateStart, setDateStart] = useState<Dayjs>(dayjs('2024-01-01'));
    const [dateStop, setDateStop] = useState<Dayjs>(dayjs());

    const processIdeas = useCallback(
        (models: TradeIdeaModel[], setterFn: any) => {
            let countStopLose = 0;
            let countStopLoseWithSuccess = 0;
            let countTarget1 = 0;
            let countTarget2 = 0;
            let countTarget3 = 0;
            let countTarget4 = 0;
            let countTarget5 = 0;
            let finishedIdeas = 0;
            let losePercentages = 0;
            let profitPercentages = 0;
            const maxTargetNum = parseInt(maxTarget);
            const resModels = models.filter((idea) => {
                let filterMoexLevel = true;
                let filterShort = true;
                const instrument = find(AppState.instruments, ({ ticker }) => ticker === idea.ticker);
                if (moexLevel) {
                    filterMoexLevel = instrument?.moexLevel.toString() === moexLevel;
                }
                if (idea.ideaType === IdeaType.Short) {
                    filterShort = instrument?.shortEnabledFlag || true;
                }
                return filterMoexLevel && filterShort && (instrument?.apiTradeAvailableFlag || true);
            });
            const totalIdeas = resModels.length;
            resModels.forEach((idea: TradeIdeaModel) => {
                const targetMode = idea.ideaType === IdeaType.Long ? 1 : -1;
                if (idea.target4236.is_executed && maxTargetNum >= 5) {
                    countTarget5++;
                    profitPercentages += getPercent(idea.buyPrice, idea.target4236.price) * targetMode;
                } else if (idea.target3618.is_executed && maxTargetNum >= 4) {
                    countTarget4++;
                    profitPercentages += getPercent(idea.buyPrice, idea.target3618.price) * targetMode;
                } else if (idea.target2618.is_executed && maxTargetNum >= 3) {
                    countTarget3++;
                    profitPercentages += getPercent(idea.buyPrice, idea.target2618.price) * targetMode;
                } else if (idea.target1618.is_executed && maxTargetNum >= 2) {
                    countTarget2++;
                    profitPercentages += getPercent(idea.buyPrice, idea.target1618.price) * targetMode;
                } else if (idea.target1percent.is_executed && maxTargetNum >= 1) {
                    countTarget1++;
                    profitPercentages += getPercent(idea.buyPrice, idea.target1percent.price) * targetMode;
                } else if (idea.stopLose.is_executed) {
                    countStopLose++;
                    losePercentages += getPercent(idea.buyPrice, idea.stopLose.price) * targetMode;
                }
                if (!idea.isActive) {
                    finishedIdeas++;
                }
            });

            countTarget1 = countTarget1 + countTarget2 + countTarget3 + countTarget4 + countTarget5;
            countTarget2 = countTarget2 + countTarget3 + countTarget4 + countTarget5;
            countTarget3 = countTarget3 + countTarget4 + countTarget5;
            countTarget4 = countTarget4 + countTarget5;

            setterFn({
                totalIdeas,
                countStopLose,
                countStopLoseWithSuccess,
                countTarget1,
                countTarget2,
                countTarget3,
                countTarget4,
                countTarget5,
                finishedIdeas,
                losePercents: losePercentages,
                profitPercents: profitPercentages,
            });
        },
        [moexLevel, maxTarget]
    );

    const getTargetIdeasPercent = useCallback(
        (count: number | undefined): string => {
            if (!tradeResults || !count) {
                return '0';
            }
            return (100 + getPercent(tradeResults.totalIdeas, count)).toFixed(2);
        },
        [tradeResults]
    );

    useEffect(() => {
        if (isActivated) {
            return;
        }
        isActivated = true;
        setTimeout(() => (isActivated = false), 1000);
        const refactorFiboDate = new Date();
        refactorFiboDate.setDate(26);
        refactorFiboDate.setMonth(10);

        AppState.restClient.fetchTradeIdeas().then(async (data) => {
            const newItems: ITradeItems = {
                items1h: [],
                items4h: [],
                items1d: [],
                items1w: [],
                items1m: [],
            };
            data.forEach((rawModel: ITradeIdea) => {
                const model = new TradeIdeaModel(rawModel);
                switch (model.timeFrame) {
                    case TimeFrame.OneHour:
                        newItems.items1h.push(model);
                        break;
                    case TimeFrame.FourHour:
                        newItems.items4h.push(model);
                        break;
                    case TimeFrame.OneDay:
                        newItems.items1d.push(model);
                        break;
                    case TimeFrame.OneWeek:
                        newItems.items1w.push(model);
                        break;
                    case TimeFrame.OneMonth:
                        newItems.items1m.push(model);
                        break;
                }
            });
            setItems(newItems);
            setIdeaType(IdeaType.Long);
        });
        return;
    }, []);

    useEffect(() => {
        let targetItems: TradeIdeaModel[] = [];

        if (includes(timeFrames, TimeFrame.OneHour)) {
            targetItems.push(...items.items1h);
        }
        if (includes(timeFrames, TimeFrame.FourHour)) {
            targetItems.push(...items.items4h);
        }
        if (includes(timeFrames, TimeFrame.OneDay)) {
            targetItems.push(...items.items1d);
        }
        if (includes(timeFrames, TimeFrame.OneWeek)) {
            targetItems.push(...items.items1w);
        }
        if (includes(timeFrames, TimeFrame.OneMonth)) {
            targetItems.push(...items.items1m);
        }

        if (targetItems.length === 0) {
            targetItems = [...items.items1h, ...items.items4h, ...items.items1d, ...items.items1w, ...items.items1m];
        }

        targetItems = targetItems.filter((item) => {
            const createDate = dayjs(item.createDate);
            let filterIdeaType = true;
            if (ideaType !== '') {
                filterIdeaType = item.ideaType === ideaType;
            }
            return createDate.isAfter(dateStart) && createDate.isBefore(dateStop) && filterIdeaType;
        });
        processIdeas(targetItems, setTradeResults);
    }, [items, moexLevel, timeFrames, ideaType, dateStart, dateStop, maxTarget]);

    const handleMoexLevelChange = (event: SelectChangeEvent) => {
        setMoexLevel(event.target.value);
    };
    const handleTimeFrameChange = (event: SelectChangeEvent<string[]>) => {
        let { value } = event.target;
        if (value.length === 0) {
            setTimeFrames([allItemsKey]);
        } else {
            // On autofill, we get a stringifies value.
            value = typeof value === 'string' ? value.split(',') : value;
            if (value.length > 1) {
                value = value.filter((item) => item !== allItemsKey);
            }
            setTimeFrames(value);
        }
    };
    const handleIdeaTypeChange = (event: SelectChangeEvent) => {
        setIdeaType(event.target.value);
    };
    const handleSelectMaxTargetChange = (event: SelectChangeEvent<string>) => {
        setMaxTarget(event.target.value);
    };
    const handleSelectOneDealPercent = (event: SelectChangeEvent<string>) => {
        setOneDealPercent(event.target.value);
    };

    const dateStartChange = useCallback((newValue: any) => setDateStart(newValue), []);
    const dateStopChange = useCallback((newValue: any) => setDateStop(newValue), []);

    if (!tradeResults) {
        return null;
    }
    const oneDealPercentNum = parseInt(oneDealPercent) * 0.01;

    return (
        <>
            {/*<Alert severity="info">*/}
            {/*    Статистика по идеям актуальна начиная с 27.11.2023 (после изменений в поиске сеток)*/}
            {/*</Alert>*/}
            <br />
            <FormControl sx={{ m: 1, minWidth: 200 }} size="medium">
                <InputLabel id="demo-select-small-label">Эшелон Мосбиржи</InputLabel>
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={moexLevel}
                    label="Эшелон Мосбиржи"
                    onChange={handleMoexLevelChange}
                >
                    <MenuItem value="">
                        <em>Все</em>
                    </MenuItem>
                    <MenuItem value={'1'}>1</MenuItem>
                    <MenuItem value={'2'}>2</MenuItem>
                    <MenuItem value={'3'}>3</MenuItem>
                    <MenuItem value={'0'}>Расписки</MenuItem>
                </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 250, maxWidth: 250 }} size="small">
                <InputLabel id="demo-select-small-label">Тайм фрейм</InputLabel>
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={timeFrames}
                    label="Тайм фрейм"
                    multiple={true}
                    multiline={true}
                    onChange={handleTimeFrameChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Тайм фрейм" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                // @ts-ignore
                                <Chip key={value} label={TimeFrameSelectItems[value]} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {Object.keys(TimeFrameSelectItems).map((itemKey) => {
                        return (
                            <MenuItem key={itemKey} value={itemKey}>
                                {/*@ts-ignore*/}
                                {TimeFrameSelectItems[itemKey]}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 200 }} size="medium">
                <InputLabel id="demo-select-small-label">Вид сделки</InputLabel>
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={ideaType}
                    label="Вид сделки"
                    onChange={handleIdeaTypeChange}
                >
                    <MenuItem value="">
                        <em>Все</em>
                    </MenuItem>
                    <MenuItem value={IdeaType.Long}>Лонг</MenuItem>
                    <MenuItem value={IdeaType.Short}>Шорт</MenuItem>
                </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                <DatePicker label="Начиная с даты" format={'D.M.YYYY'} value={dateStart} onChange={dateStartChange} />
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                <DatePicker
                    disableFuture
                    format={'D.M.YYYY'}
                    label="По дату"
                    value={dateStop}
                    onChange={dateStopChange}
                />
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 200 }} size="medium">
                <InputLabel id="select-max-target-label">Макс. цель</InputLabel>
                <Select
                    labelId="select-max-target-label"
                    id="select-max-target"
                    value={maxTarget}
                    label="Макс. цель"
                    onChange={handleSelectMaxTargetChange}
                >
                    <MenuItem value={'1'}>1</MenuItem>
                    <MenuItem value={'2'}>2</MenuItem>
                    <MenuItem value={'3'}>3</MenuItem>
                    <MenuItem value={'4'}>4</MenuItem>
                    <MenuItem value={'5'}>5</MenuItem>
                </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 200 }} size="medium">
                <InputLabel id="select-one-deal-percent-label">Процент в сделку</InputLabel>
                <Select
                    labelId="select-one-deal-percent-label"
                    id="select-one-deal-percent"
                    value={oneDealPercent}
                    label="Процент в сделку"
                    onChange={handleSelectOneDealPercent}
                >
                    <MenuItem value={'1'}>1%</MenuItem>
                    <MenuItem value={'2'}>2%</MenuItem>
                    <MenuItem value={'3'}>3%</MenuItem>
                    <MenuItem value={'4'}>4%</MenuItem>
                    <MenuItem value={'5'}>5%</MenuItem>
                    <MenuItem value={'6'}>6%</MenuItem>
                    <MenuItem value={'7'}>7%</MenuItem>
                    <MenuItem value={'8'}>8%</MenuItem>
                    <MenuItem value={'9'}>9%</MenuItem>
                    <MenuItem value={'10'}>10%</MenuItem>
                </Select>
            </FormControl>

            <TableContainer component={Paper}>
                <Table size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Показатель</StyledTableCell>
                            <StyledTableCell align={'left'}>Значение</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow hover>
                            <TableCell>Фин. рез.</TableCell>
                            <TableCell>
                                {(
                                    (tradeResults.profitPercents + tradeResults.losePercents) *
                                    oneDealPercentNum
                                ).toFixed(2)}{' '}
                                %
                            </TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell>Всего идей</TableCell>
                            <TableCell>{tradeResults.totalIdeas} шт.</TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell>Активных идей</TableCell>
                            <TableCell>{tradeResults.totalIdeas - tradeResults.finishedIdeas}</TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell>Профит идей</TableCell>
                            <TableCell>{tradeResults.countTarget1} шт.</TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell>Профит сум.</TableCell>
                            <TableCell>{(tradeResults.profitPercents * oneDealPercentNum).toFixed(2)} %</TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell>Идей достигло цели №1</TableCell>
                            <TableCell>
                                {tradeResults.countTarget1} шт. ({getTargetIdeasPercent(tradeResults?.countTarget1)}%)
                            </TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell>Идей достигло цели №2</TableCell>
                            <TableCell>
                                {tradeResults.countTarget2} шт. ({getTargetIdeasPercent(tradeResults?.countTarget2)}%)
                            </TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell>Идей достигло цели №3</TableCell>
                            <TableCell>
                                {tradeResults.countTarget3} шт. ({getTargetIdeasPercent(tradeResults?.countTarget3)}%)
                            </TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell>Идей достигло цели №4</TableCell>
                            <TableCell>
                                {tradeResults.countTarget4} шт. ({getTargetIdeasPercent(tradeResults?.countTarget4)}%)
                            </TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell>Идей достигло цели №5</TableCell>
                            <TableCell>
                                {tradeResults.countTarget5} шт. ({getTargetIdeasPercent(tradeResults?.countTarget5)}%)
                            </TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell>Убыточных идей</TableCell>
                            <TableCell>{tradeResults.countStopLose} шт.</TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell>Убыток сум. (%)</TableCell>
                            <TableCell>{(tradeResults.losePercents * oneDealPercentNum).toFixed(2)} %</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
});

export function DashboardPage() {
    const [open, setOpen] = useState(!isMobile);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const { pathname } = useLocation();

    const rout = Object.values(routing).find((item) => item.path === pathname);
    const { ticker } = useParams();
    let pageTitle = rout?.title || '';
    if (ticker) {
        const instrument = AppState.getInstrumentByTicker(ticker);
        if (instrument) {
            pageTitle = `#${ticker} - ${instrument.name}`;
        } else {
            pageTitle = `#${ticker}`;
        }
    }
    const isDashboardRoot = pathname === routing.dashboard.path;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="absolute" open={open}>
                <Toolbar
                    sx={{
                        pr: '24px', // keep right padding when drawer closed
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                        {pageTitle}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                >
                    <Typography variant="h5" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                        InvestInfo.online
                    </Typography>
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List component="nav">
                    <NavigationMenu />
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar />
                <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                    {isDashboardRoot ? <DashboardRootContent /> : <Outlet />}
                    <Copyright sx={{ pt: 4 }} />
                </Container>
            </Box>
        </Box>
    );
}
