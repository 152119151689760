import { IdeaType, TimeFrame } from '../common';
import { Condition, ObjectId } from 'mongodb';

export interface IPriceTarget {
    price: number;
    is_executed: boolean;
    time: Date | null;
}

export interface ITradeIdea {
    _id: Condition<ObjectId>;
    ticker: string;
    timeframe: TimeFrame;
    idea_type: IdeaType;
    buy_price: string;
    create_date: string;
    is_active: boolean;
    stop_lose: IPriceTarget;
    target1percent: IPriceTarget;
    target1000: IPriceTarget;
    target1618: IPriceTarget;
    target2618: IPriceTarget;
    target3618: IPriceTarget;
    target4236: IPriceTarget;
}

export class PriceTarget implements IPriceTarget {
    price: number;
    is_executed: boolean;
    time: Date | null;

    constructor(props: IPriceTarget) {
        // parseFloat need to support legacy models support legacy models
        this.price = parseFloat(props.price as any);
        this.is_executed = props.is_executed;
        this.time = props.time ? new Date(props.time) : null;
    }
}

export class TradeIdeaModel {
    id: Condition<ObjectId>;
    ticker: string;
    buyPrice: number;
    createDate: Date;
    isActive: boolean;
    stopLose: PriceTarget;
    target1percent: PriceTarget;
    target1000: PriceTarget;
    target1618: PriceTarget;
    target2618: PriceTarget;
    target3618: PriceTarget;
    target4236: PriceTarget;
    timeFrame: TimeFrame;
    ideaType: IdeaType;

    constructor(data: ITradeIdea) {
        this.id = data._id;
        this.ticker = data.ticker;
        this.timeFrame = data.timeframe;
        this.ideaType = data.idea_type || IdeaType.Long;
        this.buyPrice = parseFloat(data.buy_price);
        this.createDate = new Date(data.create_date);
        this.isActive = data.is_active;
        this.stopLose = new PriceTarget(data.stop_lose);
        this.target1000 = new PriceTarget(data.target1000);
        this.target1618 = new PriceTarget(data.target1618);
        this.target1percent = new PriceTarget(data.target1percent || { price: -1, is_executed: false, time: null });
        this.target2618 = new PriceTarget(data.target2618 || { price: -1, is_executed: false, time: null });
        this.target3618 = new PriceTarget(data.target3618 || { price: -1, is_executed: false, time: null });
        this.target4236 = new PriceTarget(data.target4236);
    }
}
