export const API = {
    session: {
        tgLogin: 'api/v1/tg_app_login',
        login: 'api/v1/login',
        removeAllOtps: 'api/v1/remove_all_otps',
        terminateAllSessions: 'api/v1/terminate_all_sessions',
    },
    promoCode: {
        add: 'api/v1/add_promocode',
        getAll: 'api/v1/promocodes',
        remove: 'api/v1/remove_promocode',
    },
    instrument: {
        deleteInstrument: 'api/v1/delete_instrument',
        findStatsByTicker: 'api/v1/instrument_stats/:ticker',
        getAll: 'api/v1/instrument',
        getAllStats: 'api/v1/instrument_stats',
        updateRatio: 'api/v1/update_instrument_ratio',
    },
    candles: {
        getCandles: 'api/v1/get_candles',
    },
    tradeIdeas: {
        getAll: 'api/v1/trade_ideas',
        deactivate: 'api/v1/deactivate_trade_idea',
    },
    fibo: {
        getFiboModels: 'api/v1/fibo',
    },
    payments: {
        tinkoff: 'api/v1/payments/tinkoff',
        getAll: 'api/v1/get_payments',
    },
    common: {
        testSendTelegramMsg: 'api/v1/test_telegram',
        getVersion: 'api/v1/version',
        sendingMessagesToTelegram: 'api/v1/send_telegram_messages',
    },
};
