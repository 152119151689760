import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { AccountType, EMPTY_TELEGRAM_ID, IUser, User, UserRiskProfile, UserRole } from '@ystb/backend/dist/user/user';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

export interface IUserDialogProps {
    userModel?: User;
    handleCloseModal: () => void;
    showModalDialog: boolean;
    onSubmit: ({ data, isEditMode }: { data: IUser; isEditMode: boolean }) => void;
}

export const UserDialog = (props: IUserDialogProps) => {
    const { handleCloseModal, showModalDialog, onSubmit, userModel } = props;
    const [userRole, setUserRole] = useState<string>(UserRole.Regular);
    const [userRiskProfile, setUserRiskProfile] = useState<string>(userModel?.riskProfile || UserRiskProfile.Undefined);
    const [accountType, setAccountType] = useState<string>(userModel?.accountType || AccountType.Investor);
    const [eula, setEula] = useState<boolean>(false);
    const [shortAllowed, setShortAllowed] = useState<boolean>(false);
    const [isQual, setIsQual] = useState<boolean>(false);
    const [sendMsg, setSendMsg] = useState<boolean>(false);
    const [expireDate, setExpireDate] = React.useState<Dayjs>(dayjs());
    const [activateDate, setActivateDate] = React.useState<Dayjs>(dayjs());

    const isEditMode = !!userModel;

    useEffect(() => {
        setUserRole(userModel?.role || UserRole.Regular);
        setUserRiskProfile(userModel?.riskProfile || UserRiskProfile.Undefined);
        setAccountType(userModel?.accountType || AccountType.Investor);
        setIsQual(userModel?.isQualInvestor || false);
        setEula(userModel?.eula || false);
        setShortAllowed(userModel?.isShortAllowed || false);
        setSendMsg(userModel?.sendPersonalNotifications || false);
        setExpireDate(dayjs(userModel?.accountExpiredAt?.toString()));
        setActivateDate(dayjs(userModel?.accountActivatedAt?.toString()));
    }, [isEditMode]);

    const handleSelectRole = useCallback((event: SelectChangeEvent) => {
        setUserRole(event.target.value);
    }, []);

    const handleSelectRiskProfile = useCallback((event: SelectChangeEvent) => {
        setUserRiskProfile(event.target.value);
    }, []);

    const handleSelectAccountType = useCallback((event: SelectChangeEvent) => {
        setAccountType(event.target.value);
    }, []);

    const handleQualChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setIsQual(event.target.checked);
    }, []);

    const handleEulaChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setEula(event.target.checked);
    }, []);

    const handleShortAllowedChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setShortAllowed(event.target.checked);
    }, []);

    const handleSendMsgChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSendMsg(event.target.checked);
    }, []);

    const onFormSubmit = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            onSubmit({
                data: {
                    telegram: formJson.telegram,
                    telegram_id: parseInt(formJson.telegramId || -1),
                    name: formJson.username,
                    email: formJson.email,
                    last_name: formJson.lastName,
                    first_name: formJson.firstName,
                    middle_name: formJson.middleName,
                    account_size: formJson.accountSize,
                    account_type: accountType,
                    account_expired_at: expireDate.toDate(),
                    account_activated_at: activateDate.toDate().toUTCString(),
                    role: userRole as UserRole,
                    risk_profile: userRiskProfile as UserRiskProfile,
                    eula,
                    send_personal_notifications: sendMsg,
                    is_short_allowed: shortAllowed,
                    is_qual_investor: isQual,
                },
                isEditMode,
            });
            handleCloseModal();
        },
        [
            accountType,
            activateDate,
            eula,
            expireDate,
            isEditMode,
            isQual,
            sendMsg,
            shortAllowed,
            userRiskProfile,
            userRole,
            handleCloseModal,
            onSubmit,
        ]
    );

    const expireDateChange = useCallback((newValue: any) => {
        setExpireDate(newValue.hour(23).minute(59).second(59));
    }, []);

    const activateDateChange = useCallback((newValue: any) => {
        setActivateDate(newValue);
    }, []);

    const dialogTitle = isEditMode ? `Редактировать пользователя "${userModel?.name}"` : 'Добавить пользователя';
    const dialogSubmitBtn = isEditMode ? 'Сохранить' : 'Добавить';

    return (
        <Dialog
            open={showModalDialog}
            onClose={handleCloseModal}
            PaperProps={{
                component: 'form',
                onSubmit: onFormSubmit,
            }}
        >
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent
                style={{
                    minWidth: 600,
                }}
            >
                <TextField
                    autoFocus={true}
                    required
                    margin="dense"
                    id="telegram"
                    name="telegram"
                    defaultValue={userModel?.telegram}
                    label="Telegram никнейм"
                    type="text"
                    fullWidth
                    variant="standard"
                />
                <TextField
                    required={true}
                    margin="dense"
                    id="name"
                    defaultValue={userModel?.name}
                    name="username"
                    label="Никнейм в системе"
                    type="text"
                    fullWidth
                    variant="standard"
                />
                <TextField
                    margin="dense"
                    id="telegramId"
                    defaultValue={userModel?.telegramId === EMPTY_TELEGRAM_ID ? '' : userModel?.telegramId}
                    name="telegramId"
                    label="Telegram id"
                    type="text"
                    fullWidth
                    variant="standard"
                />
                <TextField
                    margin="dense"
                    id="name"
                    name="email"
                    defaultValue={userModel?.email}
                    label="Email"
                    type="email"
                    fullWidth
                    variant="standard"
                />
                <TextField
                    margin="dense"
                    id="lastName"
                    name="lastName"
                    label="Фамилия"
                    defaultValue={userModel?.lastName}
                    type="text"
                    fullWidth
                    variant="standard"
                />
                <TextField
                    margin="dense"
                    id="firstName"
                    name="firstName"
                    label="Имя"
                    defaultValue={userModel?.firstName}
                    type="text"
                    fullWidth
                    variant="standard"
                />
                <TextField
                    margin="dense"
                    id="middleName"
                    name="middleName"
                    defaultValue={userModel?.middleName}
                    label="Отчество"
                    type="text"
                    fullWidth
                    variant="standard"
                />
                <TextField
                    margin="dense"
                    id="accountSize"
                    name="accountSize"
                    defaultValue={userModel?.accountSize}
                    label="Брокерский счет"
                    type="number"
                    fullWidth
                    variant="standard"
                />
                <FormControl fullWidth sx={{ marginTop: 1, marginBottom: 1, minWidth: 150 }} size="small" id="userRole">
                    <InputLabel id="select-user-role-label">Роль</InputLabel>
                    <Select labelId="select-user-role-label" value={userRole} onChange={handleSelectRole} label="Роль">
                        <MenuItem value={UserRole.Regular}>Пользователь</MenuItem>
                        <MenuItem value={UserRole.Moderator}>Модератор</MenuItem>
                        <MenuItem value={UserRole.Admin}>Администратор</MenuItem>
                    </Select>
                </FormControl>
                <FormControl
                    fullWidth
                    sx={{ marginTop: 1, marginBottom: 1, minWidth: 150 }}
                    size="small"
                    id="accountType"
                >
                    <InputLabel id="select-user-risk-profile-label">Тариф</InputLabel>
                    <Select
                        labelId="select-account-type-label"
                        value={accountType}
                        onChange={handleSelectAccountType}
                        label="Тариф"
                    >
                        <MenuItem value={AccountType.Investor}>Инвестор</MenuItem>
                        <MenuItem value={AccountType.Trader}>Трейдер</MenuItem>
                        <MenuItem value={AccountType.Speculative}>Спекулянт</MenuItem>
                    </Select>
                </FormControl>
                <FormControl
                    fullWidth
                    sx={{ marginTop: 1, marginBottom: 1, minWidth: 150 }}
                    size="small"
                    id="userRiskProfile"
                >
                    <InputLabel id="select-user-risk-profile-label">Риск профиль</InputLabel>
                    <Select
                        labelId="select-user-risk-profile-label"
                        value={userRiskProfile}
                        onChange={handleSelectRiskProfile}
                        label="Риск профиль"
                    >
                        <MenuItem value={UserRiskProfile.Undefined}>
                            <em>Не указано</em>
                        </MenuItem>
                        <MenuItem value={UserRiskProfile.Conservative}>Консервативный</MenuItem>
                        <MenuItem value={UserRiskProfile.Moderate}>Умеренный</MenuItem>
                        <MenuItem value={UserRiskProfile.Aggressive}>Агрессивный</MenuItem>
                    </Select>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                    <DatePicker label="Подписка активна с:" value={activateDate} onChange={activateDateChange} />
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                    <DatePicker label="Подписка действительна до:" value={expireDate} onChange={expireDateChange} />
                </FormControl>
                {/*Qual*/}
                <FormControl fullWidth sx={{ marginTop: 1, marginBottom: 1, minWidth: 150 }} size="small" id="qual">
                    <FormControlLabel
                        id="qual"
                        control={<Checkbox value={eula} onChange={handleQualChange} checked={isQual} />}
                        label="Квалифицированный инвестор"
                    />
                </FormControl>
                {/*Short*/}
                <FormControl
                    fullWidth
                    sx={{ marginTop: 1, marginBottom: 1, minWidth: 150 }}
                    size="small"
                    id="shortAllowed"
                >
                    <FormControlLabel
                        id="shortAllowed"
                        control={
                            <Checkbox value={shortAllowed} onChange={handleShortAllowedChange} checked={shortAllowed} />
                        }
                        label="Шорт сделки"
                    />
                </FormControl>
                {/*EULA*/}
                <FormControl fullWidth sx={{ marginTop: 1, marginBottom: 1, minWidth: 150 }} size="small" id="eula">
                    <FormControlLabel
                        id="eula"
                        control={<Checkbox value={eula} onChange={handleEulaChange} checked={eula} />}
                        label="Принято пользовательское соглашение"
                    />
                </FormControl>
                {/* Send message / trade bot start initialised */}
                <FormControl fullWidth sx={{ marginTop: 1, marginBottom: 1, minWidth: 150 }} size="small" id="sendMsg">
                    <FormControlLabel
                        id="sendMsg"
                        control={<Checkbox value={sendMsg} onChange={handleSendMsgChange} checked={sendMsg} />}
                        label="Отправлять сообщения в Telegram"
                    />
                </FormControl>
            </DialogContent>

            <DialogActions style={{ padding: '20px' }}>
                <Button onClick={handleCloseModal} color={'primary'}>
                    Отмена
                </Button>
                <Button type="submit" variant={'contained'}>
                    {dialogSubmitBtn}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
