import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import BarChartIcon from '@mui/icons-material/BarChart';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined';
import SettingsOutlined from '@mui/icons-material/SettingsOutlined';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import LayersIcon from '@mui/icons-material/Layers';
import Link from '@mui/material/Link';
import { useLocation } from 'react-router-dom';
import { routing } from '../../constants/navigation';

export function NavigationMenu() {
    const { pathname } = useLocation();

    return (<>
        <Link href={ routing.dashboard.href } underline='none'>
            <ListItemButton selected={ pathname === routing.dashboard.path }>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary={routing.dashboard.title} />
            </ListItemButton>
        </Link>
        <Link href={ routing.tradeIdeas.href } underline='none'>
            <ListItemButton selected={ pathname === routing.tradeIdeas.path }>
                <ListItemIcon>
                    <TroubleshootOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={routing.tradeIdeas.title} />
            </ListItemButton>
        </Link>
        <Link href={ routing.fibo.href } underline='none'>
            <ListItemButton selected={ pathname === routing.fibo.path }>
                <ListItemIcon>
                    <BarChartIcon />
                </ListItemIcon>
                <ListItemText primary={routing.fibo.title} />
            </ListItemButton>
        </Link>
        <Link href={ routing.instruments.href } underline='none'>
            <ListItemButton selected={ pathname === routing.instruments.path }>
                <ListItemIcon>
                    <LayersIcon />
                </ListItemIcon>
                <ListItemText primary={routing.instruments.title} />
            </ListItemButton>
        </Link>
        <Link href={ routing.screener.href } underline='none'>
            <ListItemButton selected={ pathname === routing.screener.path }>
                <ListItemIcon>
                    <AdsClickIcon />
                </ListItemIcon>
                <ListItemText primary={routing.screener.title} />
            </ListItemButton>
        </Link>
        <Link href={ routing.users.href } underline='none'>
            <ListItemButton selected={ pathname === routing.users.path }>
                <ListItemIcon>
                    <ManageAccountsOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={routing.users.title} />
            </ListItemButton>
        </Link>
        <Link href={ routing.promoCodes.href } underline='none'>
            <ListItemButton selected={ pathname === routing.promoCodes.path }>
                <ListItemIcon>
                    <PendingActionsIcon />
                </ListItemIcon>
                <ListItemText primary={routing.promoCodes.title} />
            </ListItemButton>
        </Link>
        <Link href={ routing.settings.href } underline='none'>
            <ListItemButton selected={ pathname === routing.settings.path }>
                <ListItemIcon>
                    <SettingsOutlined />
                </ListItemIcon>
                <ListItemText primary={routing.settings.title} />
            </ListItemButton>
        </Link>
    </>);
}
