import { SyntheticEvent, useCallback, useState, FormEvent } from 'react';
import { noop } from 'lodash';
import { Button, Checkbox, FormControlLabel, TextField, Box } from '@mui/material';

import AppState from '../AppState';
import { ConfirmationDialog, IDialogAction } from './components/ConfirmationDialog';
import TextArea from '../components/TextArea';

export function SettingsPage() {
    const [action, setAction] = useState<IDialogAction | null>(null);
    const [allUsersCheckbox, setAllUsersCheckbox] = useState(false);
    const [textAreaValue, setTextAreaValue] = useState('');

    const handleAllUsersChange = (event: SyntheticEvent<Element, Event>, checked: boolean) => {
        setAllUsersCheckbox(checked);
    };

    const handleCloseModal = useCallback(() => {
        setAction(null);
    }, []);

    const handleRemoveAllOtp = useCallback(() => {
        setAction({
            title: 'Удалить все одноразовые пароли',
            onSubmit: async () => {
                await AppState.restClient.deleteAllOtp();
                handleCloseModal();
                alert('Готово');
            },
        });
    }, [handleCloseModal]);
    const handleRemoveAllSessions = useCallback(() => {
        setAction({
            title: 'Деактивировать все пользовательские сессии',
            onSubmit: async () => {
                await AppState.restClient.terminateAllSessions();
                window.location.reload();
            },
        });
    }, []);

    const handleSendTestMessageSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const tgUserId = (data.get('tgUserId') || '') as string;
        AppState.restClient.sendTestTelegramMsg(tgUserId).then(() => {
            alert('Готово');
        });
    }, []);

    const handleTextAreaChange = useCallback((event: any) => {
        setTextAreaValue(event.target.value);
    }, []);

    const handleSendTelegramMessage = useCallback(
        (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const data = new FormData(event.currentTarget);
            const tgUserId = (data.get('tgUserId2') || '') as string;
            const params = {
                tgUserId: parseInt(tgUserId),
                allUsers: allUsersCheckbox,
                msg: textAreaValue,
            };
            if (!textAreaValue) {
                alert('Укажите сообщение');
                return;
            }
            AppState.restClient.sendTelegramMsg(params).then(() => {
                alert('Готово');
            });
        },
        [allUsersCheckbox, textAreaValue]
    );

    const showModal = !!action;

    return (
        <>
            <div>
                <Button variant={'outlined'} onClick={handleRemoveAllOtp} color={'error'} size={'small'}>
                    Удалить все одноразовые пароли
                </Button>
            </div>
            <br />
            <div>
                <Button variant={'outlined'} onClick={handleRemoveAllSessions} color={'error'} size={'small'}>
                    Деактивировать все пользовательские сессии
                </Button>
            </div>
            <br />
            <div>
                <Box component="form" onSubmit={handleSendTestMessageSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        autoFocus={false}
                        required
                        defaultValue="112959077"
                        margin="dense"
                        id="tgUserId"
                        name="tgUserId"
                        label="Telegram user id"
                        type="text"
                    />
                    <br />
                    <Button variant={'outlined'} type="submit" color={'primary'} size={'small'}>
                        Отправить тестовое сообщение
                    </Button>
                </Box>
            </div>
            <div>
                <hr />
                <Box component="form" onSubmit={handleSendTelegramMessage} noValidate sx={{ p: 1, my: 1 }}>
                    <TextField
                        autoFocus={false}
                        required
                        defaultValue="112959077"
                        margin="dense"
                        id="tgUserId2"
                        name="tgUserId2"
                        label="Telegram user id"
                        type="text"
                    />
                    <br />
                    <TextArea
                        aria-label="empty textarea"
                        minRows={3}
                        maxRows={12}
                        placeholder="Msg"
                        onChange={handleTextAreaChange}
                    />
                    <br />
                    <FormControlLabel
                        onChange={handleAllUsersChange}
                        control={<Checkbox />}
                        label="Отправить всем пользователям"
                    />
                    <Button variant={'contained'} type={'submit'} color={'primary'} size={'small'}>
                        Отправить
                    </Button>
                </Box>
            </div>

            <ConfirmationDialog
                dialogTitle={action?.title || ''}
                handleCloseModal={handleCloseModal}
                showModalDialog={showModal}
                onSubmit={action?.onSubmit || noop}
                submitButtonText="Удалить"
                submitButtonColor={'error'}
            />
        </>
    );
}
