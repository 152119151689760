import dayjs from 'dayjs';
import { includes } from 'lodash';
import format from 'date-fns/format';

import { TimeFrame } from '@ystb/backend/dist/common';

export function getTimeframe(timeFrame: TimeFrame): string {
    let res = '';
    switch (timeFrame) {
        case TimeFrame.OneMonth:
            res = '1mth';
            break;
        case TimeFrame.OneDay:
            res = '1d';
            break;
        case TimeFrame.OneWeek:
            res = '1w';
            break;
        case TimeFrame.OneHour:
            res = '1h';
            break;
        case TimeFrame.FourHour:
            res = '4h';
            break;
    }
    return res;
}

export function getFormattedDate(data: Date): string {
    try {
        return format(data, 'H:mm / dd.MM.yy');
    } catch (e) {
        return data.toString();
    }
}

export const getTimeFormat = (timeFormat: TimeFrame, time: Date) => {
    if (includes([TimeFrame.OneMinute, TimeFrame.OneHour, TimeFrame.FourHour], timeFormat)) {
        return dayjs(time).toDate().getTime() / 1000;
    } else {
        return dayjs(time).format('YYYY-MM-DD');
    }
};

export const getHumanReadableVolume = (volume: number): string => {
    if(volume > 1000000) {
        return `${(volume / 1000000).toFixed(2)} млн.`;
    } else if (volume > 1000) {
        return `${(volume / 1000).toFixed(2)} т.`;
    }
    return volume.toString()
}
