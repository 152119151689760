import { TimeFrame, TrendDirection } from '../common';
import { DbCandle, IDbCandle } from '../dbCandle/dbCandle';

export interface IFibo {
    ticker: string;
    timeframe: TimeFrame;
    update_date: Date;
    point1: IDbCandle;
    point2: IDbCandle;
    trend_direction: TrendDirection;
    fib4786: string;
    fib4618: string;
    fib4236: string;
    fib3927: string;
    fib3618: string;
    fib3118: string;
    fib2786: string;
    fib2618: string;
    fib2118: string;
    fib1786: string;
    fib1618: string;
    fib1000: string;
    fib382: string;
    fib236: string;
}

export class FiboModel {
    ticker: string;
    timeFrame: TimeFrame;
    updateDate: Date;
    point1: DbCandle;
    point2: DbCandle;
    trendDirection: TrendDirection;
    fib4786: number;
    fib4618: number;
    fib4236: number;
    fib3927: number;
    fib3618: number;
    fib3118: number;
    fib2786: number;
    fib2618: number;
    fib2118: number;
    fib1786: number;
    fib1618: number;
    fib1000: number;
    fib382: number;
    fib236: number;

    constructor(data: IFibo) {
        this.ticker = data.ticker;
        this.timeFrame = data.timeframe;
        this.updateDate = new Date(data.update_date);
        this.point1 = new DbCandle(data.point1);
        this.point2 = new DbCandle(data.point2);
        this.trendDirection = data.trend_direction;
        this.fib4786 = parseFloat(data.fib4786);
        this.fib4618 = parseFloat(data.fib4618);
        this.fib4236 = parseFloat(data.fib4236);
        this.fib3927 = parseFloat(data.fib3927);
        this.fib3618 = parseFloat(data.fib3618);
        this.fib3118 = parseFloat(data.fib3118);
        this.fib2786 = parseFloat(data.fib2786);
        this.fib2618 = parseFloat(data.fib2618);
        this.fib2118 = parseFloat(data.fib2118);
        this.fib1786 = parseFloat(data.fib1786);
        this.fib1618 = parseFloat(data.fib1618);
        this.fib1000 = parseFloat(data.fib1000);
        this.fib382 = parseFloat(data.fib382);
        this.fib236 = parseFloat(data.fib236);
    }
}
