export const routing = {
    auth: {
        href: '#/auth',
        path: '/auth',
        title: 'Авторизация'
    },
    tgChart: {
        href: '#/chart/:ticker',
        path: '/chart/:ticker',
        title: 'График'
    },
    dashboard: {
        href: '#/dashboard',
        path: '/dashboard',
        title: 'Главная',
    },
    tradeIdeas: {
        href: '#/dashboard/trade_ideas',
        path: '/dashboard/trade_ideas',
        title: 'Торговые идеи',
    },
    fibo: {
        href: '#/dashboard/fibo',
        path: '/dashboard/fibo',
        title: 'Сетки',
    },
    instruments: {
        href: '#/dashboard/instruments',
        path: '/dashboard/instruments',
        title: 'Инструменты',
    },
    instrumentDetails: {
        href: '#/dashboard/instrument/:ticker',
        path: '/dashboard/instrument/:ticker',
        title: 'Инструмент',
    },
    screener: {
        href: '#/dashboard/screener',
        path: '/dashboard/screener',
        title: 'Подбор акций',
    },
    users: {
        href: '#/dashboard/users',
        path: '/dashboard/users',
        title: 'Пользователи',
    },
    settings: {
        href: '#/dashboard/settings',
        path: '/dashboard/settings',
        title: 'Настройки',
    },
    promoCodes: {
        href: '#/dashboard/promocodes',
        path: '/dashboard/promocodes',
        title: 'Промокоды',
    },
    eula: {
        href: '#/eula',
        path: '/eula',
        title: 'eula',
    },
};
