import { CandlesChart, IChartCandle } from '../components/CandlesChart';
import { uniqBy } from 'lodash';
import { useCallback, useEffect, useState, ChangeEvent, SyntheticEvent } from 'react';
import { useParams } from 'react-router-dom';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { InstrumentsAutocomplete } from '../components/InstrumentsAutocomplete';
import { getTimeFormat } from '../utils/common';

import AppState from '../AppState';

import { RawDbCandle } from '@ystb/backend/dist/dbCandle/dbCandle';
import { FiboModel, IFibo } from '@ystb/backend/dist/fibo/fiboModel';
import { TimeFrame, TrendDirection } from '@ystb/backend/dist/common';

let isActivated = false;
let isFetchingFibo = false;

const TimeFrameSelectItems = {
    [TimeFrame.OneMinute]: '1 минута',
    [TimeFrame.OneHour]: '1 час',
    [TimeFrame.FourHour]: '4 часа',
    [TimeFrame.OneDay]: '1 день',
    [TimeFrame.OneWeek]: '1 неделя',
    [TimeFrame.OneMonth]: '1 месяц',
};

export function InstrumentDetailsPage() {
    const { ticker } = useParams();

    const [candles, setCandles] = useState<IChartCandle[]>([]);
    const [timeFrame, setTimeFrame] = useState<TimeFrame>(TimeFrame.OneDay);
    const [activeFiboModel, setActiveFiboModel] = useState<FiboModel | null>(null);
    const [showSMA9, setShowSMA9] = useState<boolean>(false);
    const [showSMA21, setShowSMA21] = useState<boolean>(false);
    const [showSMA200, setShowSMA200] = useState<boolean>(false);
    const [showEMA12, setShowEMA12] = useState<boolean>(false);
    const [showEMA26, setShowEMA26] = useState<boolean>(false);
    const [showEMA200, setShowEMA200] = useState<boolean>(true);
    const [showFibo, setShowFibo] = useState<boolean>(true);
    const [showPattern, setShowPattern] = useState<boolean>(false);
    const [showVolume, setShowVolume] = useState<boolean>(true);
    const [showPriceGap, setShowPriceGap] = useState<boolean>(false);
    const [selectedTicker, setSelectedTicker] = useState<string>(ticker || '');
    const [instrumentFiboModels, setInstrumentFiboModels] = useState<FiboModel[]>([]);

    const loadCandles = useCallback(() => {
        if (!selectedTicker) {
            return;
        }
        let limit = timeFrame === TimeFrame.OneMinute ? 10000 : 5000;
        AppState.restClient.getCandles(selectedTicker, timeFrame, limit).then((rawCandles) => {
            let candles: IChartCandle[] = rawCandles.reverse().map((model) => {
                const rawCandle = new RawDbCandle(model);

                return {
                    open: rawCandle.open,
                    close: rawCandle.close,
                    low: rawCandle.low,
                    high: rawCandle.high,
                    time: getTimeFormat(timeFrame, dayjs(rawCandle.time).add(3, 'hour').toDate()),
                    volume: rawCandle.volume,
                    timeFrame: timeFrame,
                };
            });
            setCandles(uniqBy(candles, 'time'));
        });
    }, [selectedTicker, timeFrame, setCandles]);

    useEffect(() => {
        if (isActivated) {
            return;
        }
        isActivated = true;
        setTimeout(() => (isActivated = false), 1000);
        loadCandles();
    }, [selectedTicker, loadCandles]);

    useEffect(() => {
        if (!selectedTicker || isFetchingFibo) {
            return;
        }
        isFetchingFibo = true;
        AppState.restClient
            .fetchFibo(selectedTicker)
            .then((data) => {
                const fiboModels: FiboModel[] = data
                    .filter((item: IFibo) => !!item.update_date)
                    .map((rawModel: IFibo) => {
                        const fibo = new FiboModel(rawModel)
                        fibo.point1.time = dayjs(fibo.point1.time).add(3, 'hour').toDate();
                        fibo.point2.time = dayjs(fibo.point2.time).add(3, 'hour').toDate();
                        return fibo;
                    });

                setInstrumentFiboModels(fiboModels || []);
            })
            .finally(() => {
                isFetchingFibo = false;
            });
    }, [selectedTicker]);

    useEffect(() => {
        if (ticker && selectedTicker !== ticker) {
            setSelectedTicker(ticker);
        }
    }, [ticker, selectedTicker]);

    useEffect(() => {
        const fibo = instrumentFiboModels.find((fibo) => fibo.timeFrame === timeFrame);
        if (fibo?.timeFrame !== activeFiboModel?.timeFrame || fibo?.ticker !== activeFiboModel?.ticker) {
            setActiveFiboModel(fibo || null);
        }
    }, [instrumentFiboModels, timeFrame, setActiveFiboModel]);

    const handleCandleTypeChange = useCallback((event: SelectChangeEvent<string>) => {
        const newTimeFrame = event.target.value as TimeFrame;
        setTimeFrame(newTimeFrame);
    }, []);

    const handleTickerChange = useCallback((event: SyntheticEvent, value: any) => {
        // @ts-ignore
        window.location = `/#/dashboard/instrument/${value.ticker}`;
    }, []);
    const handleChangeEMA12 = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setShowEMA12(event.target.checked);
    }, []);
    const handleChangeEMA26 = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setShowEMA26(event.target.checked);
    }, []);
    const handleChangeEMA200 = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setShowEMA200(event.target.checked);
    }, []);
    const handleChangeSMA9 = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setShowSMA9(event.target.checked);
    }, []);
    const handleChangeSMA21 = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setShowSMA21(event.target.checked);
    }, []);
    const handleChangeSMA200 = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setShowSMA200(event.target.checked);
    }, []);
    const handleChangeFibo = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setShowFibo(event.target.checked);
    }, []);
    const handleChangePattern = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setShowPattern(event.target.checked);
    }, []);
    const handleChangePriceGap = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setShowPriceGap(event.target.checked);
    }, []);
    const handleChangeVolume = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setShowVolume(event.target.checked);
    }, []);

    return (
        <>
            <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                <InstrumentsAutocomplete
                    onChange={handleTickerChange}
                    ticker={selectedTicker}
                    disableClearable={true}
                />
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120, maxWidth: 120 }} size="small">
                <InputLabel id="timeframe-select-small-label">Тайм фрейм</InputLabel>
                <Select
                    labelId="timeframe-select-small-label"
                    id="timeframe-select-small"
                    value={timeFrame}
                    label="Тайм фрейм"
                    onChange={handleCandleTypeChange}
                >
                    {Object.keys(TimeFrameSelectItems).map((itemKey) => {
                        const fibo = instrumentFiboModels.find((model) => model.timeFrame === itemKey);
                        const direction = fibo?.trendDirection || null;
                        return (
                            <MenuItem key={itemKey} value={itemKey}>
                                {direction === TrendDirection.Up && (
                                    <ArrowUpwardOutlinedIcon color={'success'} fontSize={'small'} />
                                )}
                                {direction === TrendDirection.Down && (
                                    <ArrowDownwardOutlinedIcon color={'error'} fontSize={'small'} />
                                )}
                                {!direction && <QuestionMarkOutlinedIcon fontSize={'small'} />}
                                {/*@ts-ignore*/}
                                {TimeFrameSelectItems[itemKey]}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>

            <FormControlLabel
                control={
                    <Checkbox
                        onChange={handleChangeEMA12}
                        checked={showEMA12}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 16, color: '#2b98ee' } }}
                    />
                }
                label={
                    <Typography fontSize={14} color="#2b98ee">
                        EMA12
                    </Typography>
                }
            />

            <FormControlLabel
                control={
                    <Checkbox
                        onChange={handleChangeEMA26}
                        checked={showEMA26}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 16, color: '#FF7F00' } }}
                    />
                }
                label={
                    <Typography fontSize={14} color="#FF7F00">
                        EMA26
                    </Typography>
                }
            />

            <FormControlLabel
                control={
                    <Checkbox
                        onChange={handleChangeEMA200}
                        checked={showEMA200}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 16, color: '#800000' } }}
                    />
                }
                label={
                    <Typography fontSize={14} color="#800000">
                        EMA200
                    </Typography>
                }
            />

            <FormControlLabel
                control={
                    <Checkbox
                        onChange={handleChangeSMA9}
                        checked={showSMA9}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 16, color: '#2b98ee' } }}
                    />
                }
                label={
                    <Typography fontSize={14} color="#2b98ee">
                        SMA9
                    </Typography>
                }
            />
            <FormControlLabel
                control={
                    <Checkbox
                        onChange={handleChangeSMA21}
                        checked={showSMA21}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 16, color: '#FF7F00' } }}
                    />
                }
                label={
                    <Typography fontSize={14} color="#FF7F00">
                        SMA21
                    </Typography>
                }
            />
            <FormControlLabel
                control={
                    <Checkbox
                        onChange={handleChangeSMA200}
                        checked={showSMA200}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 16, color: '#800000' } }}
                    />
                }
                label={
                    <Typography fontSize={14} color="#800000">
                        SMA200
                    </Typography>
                }
            />
            <FormControlLabel
                control={
                    <Checkbox
                        onChange={handleChangeFibo}
                        checked={showFibo}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }}
                    />
                }
                label={<Typography fontSize={14}>FIBO</Typography>}
            />

            <FormControlLabel
                control={
                    <Checkbox
                        onChange={handleChangePattern}
                        checked={showPattern}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }}
                    />
                }
                label={<Typography fontSize={14}>Pattern</Typography>}
            />

            <FormControlLabel
                control={
                    <Checkbox
                        onChange={handleChangePriceGap}
                        checked={showPriceGap}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }}
                    />
                }
                label={<Typography fontSize={14}>Price Gap</Typography>}
            />

            <FormControlLabel
                control={
                    <Checkbox
                        onChange={handleChangeVolume}
                        checked={showVolume}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }}
                    />
                }
                label={<Typography fontSize={14}>Объемы</Typography>}
            />

            <CandlesChart
                data={candles}
                timeFrame={timeFrame as unknown as TimeFrame}
                fibo={showFibo ? activeFiboModel : null}
                showEMA12={showEMA12}
                showEMA26={showEMA26}
                showEMA200={showEMA200}
                showSMA9={showSMA9}
                showSMA21={showSMA21}
                showSMA200={showSMA200}
                showPattern={showPattern}
                showVolume={showVolume}
                showPriceGap={showPriceGap}
                paddingHeight={250}
                showCurrentCandleDetails={true}
            />
        </>
    );
}
