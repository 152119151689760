import * as React from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export function EulaPage() {
    return (
        <Container component="main" maxWidth="lg">
            <CssBaseline />
            <Box sx={{ width: '100%', maxWidth: 700 }}>
                <Typography variant="h5" gutterBottom>
                    Пользовательское соглашение
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Принимая данное пользовательское соглашение Пользователь подтверждает, что проинформирован и
                    согласен с тем, что:
                    <ul>
                        <li>вся предоставляемая информация не является индивидуальной инвестиционной рекомендацией</li>
                        <li>
                            информация, предоставляемая путем сообщений в мессенджере Telegram, не является призывом к
                            покупке или продаже ценных бумаг
                        </li>
                        <li>
                            Пользователь самостоятельно принимает решение о сделках и
                            сам несет за них полную ответственность
                        </li>
                        <li>
                            Пользователь прошел тестирование своего риск-профиля и указал соответствующее значение путем выбора пункта меню в этом Telegram боте
                        </li>
                        <li>
                            Пользователь обязуется не пересылать сообщения бота в мессенджере Telegram и/или копировать сообщения, и/или распространять иным способом содержимое сообщений как полностью, так и частично
                        </li>
                        <li>
                            пользовательское соглашение может быть изменено путем публикации новой версии
                        </li>
                    </ul>
                </Typography>
            </Box>
        </Container>
    );
}
