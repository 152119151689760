import { IRawPrice } from '../dbCandle/dbCandle';

export function getPercent(price1: number, price2: number) {
    return ((price2 - price1) / price1) * 100;
}

export function getPercentStr(price1: number, price2: number, mode = 1) {
    return (getPercent(price1, price2) * mode).toFixed(2);
}

export const rawPriceToNumber = (data: IRawPrice): number => {
    let nano = String(data.nano);
    nano = nano.padStart(9, '0');
    return parseFloat(`${data.units}.${nano}`);
};
