export const DEBUG = process.env.YS_DEBUG;

export const LOG_LEVEL = 'debug';

// RabbitMQ Configs
export const RABBITMQ_HOST = DEBUG ? 'localhost' : process.env.RABBITMQ_HOST;
export const RABBITMQ_USER = process.env.RABBITMQ_USER;
export const RABBITMQ_PASS = process.env.RABBITMQ_PASS;
export const RABBITMQ_PORT = Number(process.env.RABBITMQ_PORT);
// Stocks Bot MQ
export const RABBITMQ_TELEGRAM_QUEUE_NAME = process.env.RABBITMQ_TELEGRAM_QUEUE_NAME;
export const RABBITMQ_TELEGRAM_DL_QUEUE_NAME = process.env.RABBITMQ_TELEGRAM_DL_QUEUE_NAME;
export const RABBITMQ_TELEGRAM_DL_TTL = process.env.RABBITMQ_TELEGRAM_DL_TTL;
export const RABBITMQ_EXCHANGE_NAME = process.env.RABBITMQ_EXCHANGE_NAME;
// Info Bot MQ
export const RABBITMQ_TELEGRAM_INFO_BOT_QUEUE_NAME = process.env.RABBITMQ_TELEGRAM_INFO_BOT_QUEUE_NAME;
export const RABBITMQ_TELEGRAM_INFO_BOT_DL_QUEUE_NAME = process.env.RABBITMQ_TELEGRAM_INFO_BOT_DL_QUEUE_NAME;
export const RABBITMQ_TELEGRAM_INFO_BOT_DL_TTL = process.env.RABBITMQ_TELEGRAM_INFO_BOT_DL_TTL;
export const RABBITMQ_INFO_BOT_EXCHANGE_NAME = process.env.RABBITMQ_INFO_BOT_EXCHANGE_NAME;

// Telegram Channels
export const TG_CHANNEL_SYSTEM = Number(process.env.TG_CHANNEL_SYSTEM);
export const TG_CHANNEL_RUS_STOCKS = Number(process.env.TG_CHANNEL_RUS_STOCKS);
export const TG_CHANNEL_TRADING_ANALYTICS = Number(process.env.TG_CHANNEL_TRADING_ANALYTICS);
export const TG_CHANNEL_TEAM_PRIVATE = Number(process.env.TG_CHANNEL_TEAM_PRIVATE);

// YS_SERVICE
export const YS_SERVICE_DOMAIN = process.env.YS_SERVICE_DOMAIN;
export const API_SERVICE_PORT = process.env.API_SERVICE_PORT || 3001;

// Mongo
export const { MONGODB_HOST, MONGODB_USERNAME, MONGODB_PASSWORD, MONGODB_PORT, MONGODB_DB } = process.env;
