import { FiboModel } from '@ystb/backend/dist/fibo/fiboModel';
import { IdeaType } from '@ystb/backend/dist/common';
import { getPercent } from '@ystb/backend/dist/utils/price';
import { TradeIdeaModel } from '@ystb/backend/dist/tradeIdea/tradeIdeaModel';

export enum TradeIdeaValidationResponse {
    FiboDoesNotExist = 'FiboDoesNotExist',
    TargetDoesNotMatchFibo = 'TargetDoesNotMatchFibo',
    IsValid = 'IsValid',
}

export interface ITradeIdeaValidationResponse {
    type: TradeIdeaValidationResponse;
    percent?: number;
}

export function validateTradeIdea(tradeIdea: TradeIdeaModel, fiboModel?: FiboModel): ITradeIdeaValidationResponse {
    if (!tradeIdea) {
        throw new Error('Invalid arguments. tradeIdea is required param');
    }

    if (!fiboModel) {
        return { type: TradeIdeaValidationResponse.FiboDoesNotExist };
    } else {
        if (tradeIdea.ideaType === IdeaType.Long && tradeIdea.target4236.price > fiboModel.fib4236) {
            return {
                type: TradeIdeaValidationResponse.TargetDoesNotMatchFibo,
                percent: getPercent(fiboModel.fib4236, tradeIdea.target4236.price),
            };
        }
        if (tradeIdea.ideaType === IdeaType.Short && tradeIdea.target4236.price < fiboModel.fib4236) {
            return {
                type: TradeIdeaValidationResponse.TargetDoesNotMatchFibo,
                percent: getPercent(fiboModel.fib4236, tradeIdea.target4236.price) * -1,
            };
        }
    }
    return { type: TradeIdeaValidationResponse.IsValid };
}

export function isLongIdea(tradeIdea: TradeIdeaModel): boolean {
    return tradeIdea.ideaType === IdeaType.Long;
}

export function isShortIdea(tradeIdea: TradeIdeaModel): boolean {
    return tradeIdea.ideaType === IdeaType.Short;
}
