import { AccountType } from '../user/user';

export interface IPromoCode {
    code: string;
    limit: number;
    total: number;
    created_date: Date;
    expired_date: Date;
    account_type: string;
    month_count: number;
}

export interface IPromoCodeCreateData {
    code: string;
    limit: number;
    expired_date: Date;
    account_type: string;
    month_count: number;
}

export class PromoCodeModel {
    code: string;
    readonly limit: number;
    total: number;
    createdDate: Date;
    expiredDate: Date;
    accountType: AccountType;
    monthCount: number;

    constructor(data: IPromoCode) {
        this.code = data.code;
        this.limit = data.limit;
        this.total = data.total;
        this.createdDate = data.created_date;
        this.expiredDate = data.expired_date;
        this.accountType = data.account_type as AccountType;
        this.monthCount = data.month_count;
    }

    toJson = (): IPromoCode => {
        return {
            code: this.code,
            limit: this.limit,
            total: this.total,
            created_date: this.createdDate,
            expired_date: this.expiredDate,
            account_type: this.accountType,
            month_count: this.monthCount,
        };
    };
}
