import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import Button from '@mui/material/Button';
import * as React from 'react';

export interface IConfirmationDialogProps {
    dialogTitle: string;
    submitButtonText?: string;
    submitButtonColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    handleCloseModal: () => void;
    showModalDialog: boolean;
    onSubmit: () => void;
}

export interface IDialogAction {
    title: string;
    onSubmit: () => void;
}

export const ConfirmationDialog = (props: IConfirmationDialogProps) => {
    const {
        handleCloseModal,
        showModalDialog,
        onSubmit,
        dialogTitle,
        submitButtonText = 'Ок',
        submitButtonColor = 'primary',
    } = props;

    return (
        <Dialog
            open={ showModalDialog }
            onClose={ handleCloseModal }
            PaperProps={ {
                component: 'form',
                onSubmit: onSubmit,
            } }
        >
            <DialogTitle>{ dialogTitle }</DialogTitle>
            <DialogContent
                style={ {
                    minWidth: 600,
                } }>
            </DialogContent>

            <DialogActions style={ { padding: '20px' } }>
                <Button onClick={ handleCloseModal } color={ 'primary' }>Отмена</Button>
                <Button type='submit' variant={ 'contained' } color={ submitButtonColor }>{ submitButtonText }</Button>
            </DialogActions>
        </Dialog>
    );
};
