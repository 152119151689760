import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import * as React from 'react';
import { useCallback } from 'react';
import { InstrumentModelWithStats } from '../../models/InstrumentModelWithStats';

export interface IInstrumentDialogProps {
    instrument: InstrumentModelWithStats | null;
    handleCloseModal: () => void;
    showModalDialog: boolean;
    onSubmit: (params: IInstrumentDialogSubmitData) => void;
}

export interface IInstrumentDialogSubmitData {
    instrument: InstrumentModelWithStats;
    ratio: number;
}

export const InstrumentDialog = (props: IInstrumentDialogProps) => {
    const { handleCloseModal, showModalDialog, onSubmit, instrument } = props;

    const onFormSubmit = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            if (instrument) {
                onSubmit({
                    instrument,
                    ratio: formJson.ratio,
                });
            } else {
                console.warn('Submit edit instrument dialog failed - empty instrument model');
                handleCloseModal();
            }
        },
        [instrument, handleCloseModal, onSubmit]
    );

    const dialogTitle = `${instrument?.ticker} (${instrument?.name})`;
    const dialogSubmitBtnTitle = 'Изменить';

    return (
        <Dialog
            open={showModalDialog}
            onClose={handleCloseModal}
            PaperProps={{
                component: 'form',
                onSubmit: onFormSubmit,
            }}
        >
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent
                style={{
                    minWidth: 600,
                }}
            >
                <TextField
                    autoFocus={true}
                    required={true}
                    margin="dense"
                    id="ratio"
                    name="ratio"
                    label="Финансовый рейтинг"
                    type="number"
                    InputProps={{ inputProps: { min: -100, max: 200 } }}
                    fullWidth
                    variant="standard"
                    defaultValue={instrument?.ratio || 0}
                />
            </DialogContent>

            <DialogActions style={{ padding: '20px' }}>
                <Button onClick={handleCloseModal} color={'primary'}>
                    Отмена
                </Button>
                <Button type="submit" variant={'contained'}>
                    {dialogSubmitBtnTitle}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
