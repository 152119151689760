import { IRawCandle, RawDbCandle } from '../dbCandle/dbCandle';

export interface ICandleData {
    low: number;
    high: number;
    open: number;
    close: number;
}

export interface ICandleParts {
    bodyPercent: number;
    bodyValue: number;
    candleLength: number;
    highShadePercent: number;
    highShadeValue: number;
    lowShadePercent: number;
    lowShadeValue: number;
    onePercent: number;
}

export function getCandleParts(candle: ICandleData): ICandleParts {
    let body: number;
    let lowShade: number;
    const onePercent = (candle.high - candle.low) / 100;
    const highShade = candle.high - Math.max(candle.open, candle.close);
    if (candle.open > candle.close) {
        body = candle.open - candle.close;
        lowShade = candle.close - candle.low;
    } else {
        body = candle.close - candle.open;
        lowShade = candle.open - candle.low;
    }
    return {
        onePercent,
        bodyValue: body,
        bodyPercent: body / onePercent,
        lowShadeValue: lowShade,
        lowShadePercent: lowShade / onePercent,
        highShadeValue: highShade,
        highShadePercent: highShade / onePercent,
        candleLength: candle.high - candle.low,
    };
}

export function calcAggregatedCandle(candles: IRawCandle[]): ICandleData {
    const firstCandle = new RawDbCandle(candles[candles.length - 1]);
    const lastCandle = new RawDbCandle(candles[0]);
    let min = firstCandle.low;
    let max = firstCandle.high;
    candles.forEach((data) => {
        const candle = new RawDbCandle(data);
        if (candle.high > max) {
            max = candle.high;
        }
        if (candle.low < min) {
            min = candle.low;
        }
    });
    return {
        low: min,
        high: max,
        open: firstCandle.open,
        close: lastCandle.close,
    };
}
