// import { InstrumentModel } from '@ystb/backend/dist/instrument/instrumentModel';
import { RestClient } from './RestClient';
import format from 'date-fns/format';
import { IInstrument } from '@ystb/backend/dist/instrument/instrumentModel';
import { IInstrumentStats } from '@ystb/backend/dist/instrument/InstrumentStatsModel';
import { find } from 'lodash';
import { InstrumentModelWithStats } from './models/InstrumentModelWithStats';

const DEBUG = process.env.REACT_APP_DEBUG;
const YS_API_HOST = DEBUG ? 'http://localhost:3001' : process.env.REACT_APP_API_HOST || '';

class AppState {
    instruments: InstrumentModelWithStats[] = [];
    isAuthorised = false;

    restClient: RestClient;

    constructor() {
        const apiHost = YS_API_HOST;
        this.restClient = new RestClient({ apiHost });
    }

    async init() {
        if (this.isAuthorised) {
            return;
        }
        try {
            const models: IInstrument[] = await this.restClient.fetchInstruments();
            const stats: IInstrumentStats[] = await this.restClient.fetchInstrumentsStats();
            this.instruments = models.map((model) => {
                const statsModel = find(stats, ({ ticker }) => model.ticker === ticker);
                return new InstrumentModelWithStats(model, statsModel);
            });
            this.isAuthorised = true;
        } catch (e) {
            this.isAuthorised = false;
            console.warn('init() smth went wrong');
        }
    }

    getFormatedDate(data: string): string {
        try {
            return format(new Date(data), 'H:mm / dd.MM.yy');
        } catch (e) {
            return data.toString();
        }
    }

    getInstrumentByTicker(ticker: string): InstrumentModelWithStats | undefined {
        return this.instruments.find((instrument: InstrumentModelWithStats) => instrument.ticker === ticker);
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AppState();
