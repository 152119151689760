import React, { useEffect } from 'react';
import './PanelApp.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { createHashRouter, Outlet, redirect, RouterProvider } from 'react-router-dom';

import { SignInPage } from '../auth/sing-in-page';
import { DashboardPage } from '../dashboard/DashboardPage';
import { InstrumentsTable } from '../dashboard/InstrumentsTable';
import { TradeIdeasTable } from '../dashboard/TradeIdeasTable';
import { FiboTable } from '../dashboard/FiboTable';
import AppState from '../AppState';
import { routing } from '../constants/navigation';
import { ScreenerTable } from '../dashboard/ScreenerTable';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { UsersTable } from '../dashboard/UsersTable';
import { SettingsPage } from '../dashboard/SettingsPage';
import { PromoCodesPage } from '../dashboard/PromoCodesPage';
import { EulaPage } from '../pages/eula.page';
import { InstrumentDetailsPage } from '../dashboard/InstrumentDetailsPage';
import { SuperChartPage } from '../telegramApp/superChart';

const defaultTheme = createTheme();
// @ts-ignore
const loader = async ({ request }) => {
    if (/\/eula/.test(request.url)) {
        return {};
    }
    await AppState.init();
    if (AppState.isAuthorised) {
        if (/\/chart/.test(request.url)) {
            return {};
        } else if (!/\/dashboard/.test(request.url)) {
            return redirect(routing.dashboard.path);
        }
    } else {
        if (!/\/auth/.test(request.url)) {
            return redirect(routing.auth.path);
        }
    }
    return {};
};

const router = createHashRouter([
    {
        id: 'root',
        path: '/',
        loader: async () => {
            return {};
        },
        element: (
            <div className={'wrapper'}>
                <Outlet />
            </div>
        ),
        children: [
            {
                path: routing.auth.path,
                loader,
                element: <SignInPage />,
            },
            {
                path: routing.eula.path,
                loader,
                element: <EulaPage />,
            },
            {
                path: routing.tgChart.path,
                loader,
                element: <SuperChartPage />
            },
            {
                path: routing.dashboard.path,
                element: <DashboardPage />,
                loader,
                children: [
                    {
                        path: routing.instrumentDetails.path,
                        element: <InstrumentDetailsPage />,
                    },
                    {
                        path: routing.instruments.path,
                        element: <InstrumentsTable />,
                    },
                    {
                        path: routing.tradeIdeas.path,
                        element: <TradeIdeasTable />,
                    },
                    {
                        path: routing.fibo.path,
                        element: <FiboTable />,
                    },
                    {
                        path: routing.screener.path,
                        element: <ScreenerTable />,
                    },
                    {
                        path: routing.users.path,
                        element: <UsersTable />,
                    },
                    {
                        path: routing.settings.path,
                        element: <SettingsPage />,
                    },
                    {
                        path: routing.promoCodes.path,
                        element: <PromoCodesPage />,
                    },
                ],
            },
            {
                path: '/logout',
                async action() {
                    // todo logout request
                    return redirect('/');
                },
            },
        ],
    },
]);

function PanelApp() {
    // Telegram App
    useEffect(() => {
        window.addEventListener('message', (event) => {
            if (!event?.data?.ysEvent) {
                return;
            }
            const { username, otp } = event.data.ysEvent;
            if (username && otp) {
                AppState.restClient
                    .login({ username, otp })
                    .then((token) => {
                        // @ts-ignore
                        window.top.postMessage({ code: 'success' }, '*');
                        window.location.href = '/#/chart/SBER';
                    })
                    .catch(() => {
                        // @ts-ignore
                        window.top.postMessage({ code: 'error' }, '*');
                        window.location.href = '/404.html';
                    });
            }
        });
    }, []);

    return (
        <ThemeProvider theme={defaultTheme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <RouterProvider router={router} />
            </LocalizationProvider>
        </ThemeProvider>
    );
}

export default PanelApp;
