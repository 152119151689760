import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import PanelApp from './app/PanelApp';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);
root.render(
    <React.StrictMode>
        <PanelApp />
    </React.StrictMode>,
);



