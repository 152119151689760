export function get_now(): Date {
    return new Date(new Date().toUTCString());
}

export function rtrim(str: string, x: string): string {
    // This implementation removes whitespace from the right side
    // of the input string.
    const r = new RegExp(`${x}+$`, 'gm');
    return str.replace(r, '');
}

export function prettyPrintPrice(value = 0, maxDigits = 6): string {
    switch (typeof value) {
        case 'object':
        case 'undefined':
            return '';
        case 'string':
            return value;
    }
    let tmpStr = rtrim(value.toFixed(maxDigits), '0');
    const tmpStrLen = tmpStr.split('.')[1].length;
    if (tmpStr.split('.')[1].length < 2) {
        tmpStr += '0'.repeat(2 - tmpStrLen);
    }
    return tmpStr;
}
