import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { find } from 'lodash';
import { FormControl, InputLabel, Link, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

// @ts-ignore
import { FiboModel, IFibo } from '@ystb/backend/dist/fibo/fiboModel';
// @ts-ignore
import { TimeFrame, TrendDirection } from '@ystb/backend/dist/common';

import AppState from '../AppState';
import { InstrumentsAutocomplete, SelectTickerOption } from '../components/InstrumentsAutocomplete';

enum RatioOptions {
    None = '',
    High = 'high',
    Normal = 'normal',
    Low = 'low',
}

interface IInstrumentScreenerView {
    id: string;
    name: string;
    ticker: string;
    moexLevel: number;
    ratio: number | null;
    lastPrice: number;
    buyPriceRatio: number;
}

let isActivated = false;

export function ScreenerTable() {
    const [items, setItems] = useState<IInstrumentScreenerView[]>([]);
    const [ticker, setTicker] = useState<SelectTickerOption>();
    const [ratioSelect, setRatioSelect] = useState<RatioOptions>(RatioOptions.High);
    const [moexLevel, setMoexLevel] = useState<string>('');

    useEffect(() => {
        if (isActivated) {
            return;
        }
        isActivated = true;
        setTimeout(() => (isActivated = false), 1000);
        AppState.restClient.fetchFibo().then(async (data) => {
            const models: FiboModel[] = data
                .filter((item: IFibo) => !!item.update_date && item.timeframe === TimeFrame.OneMonth)
                .map((rawModel: IFibo) => new FiboModel(rawModel));

            const instruments = AppState.instruments.map((item) => {
                const fibo = find(models, ({ ticker }) => ticker === item.ticker);
                let buyPriceRatio = 0;
                if (fibo && fibo.trendDirection === TrendDirection.Up) {
                    const point1Price = fibo.point1.low;
                    const point2Price = fibo.point2.high;
                    buyPriceRatio = ((item.lastPrice - point1Price) / (point2Price - point1Price)) * 100;
                }
                return {
                    id: item.ticker,
                    ticker: item.ticker,
                    name: item.name,
                    moexLevel: item.moexLevel,
                    ratio: item.ratio,
                    lastPrice: item.lastPrice,
                    buyPriceRatio: buyPriceRatio,
                } as IInstrumentScreenerView;
            });
            setItems(instruments);
        });
        return;
    }, []);

    const handleTickerChange = (event: SyntheticEvent, value: any) => {
        setTicker(value || '');
    };

    const handleRatioChange = (event: SelectChangeEvent) => {
        setRatioSelect(event.target.value as RatioOptions);
    };
    const handleMoexLevelChange = (event: SelectChangeEvent) => {
        setMoexLevel(event.target.value);
    };

    const columns: GridColDef[] = [
        {
            field: 'ticker',
            headerName: 'Тикер',
            width: 300,
            renderCell: (params) => {
                const row = params.row;
                return (
                    <div key={row.ticker}>
                        <Link underline={'none'} href={`/#/dashboard/instrument/${row.ticker}`}>
                            #{row.ticker}
                        </Link>&nbsp;{row.name}
                    </div>
                );
            },
        },
        {
            field: 'ratio',
            headerName: 'Рейтинг компании по фин. отчетности (%)',
            width: 220,
            align: 'center',
            valueGetter: (ratio: number) => {
                return ratio ? `${ratio}` : '-';
            },
        },
        {
            field: 'buyPriceRatio',
            headerName: 'Рейтинг цены покупки (%)',
            width: 220,
            type: 'number',
            align: 'center',
            description: 'Оптимальные значения от 23,6 до 38,2',
        },
        {
            field: 'lastPrice',
            headerName: 'Цена',
            description: 'Цена последней минутной свечи',
            type: 'number',
        },
        {
            field: 'moexLevel',
            headerName: 'Эшелон Мосбиржи',
            width: 140,
            align: 'center',
        },
    ];

    const filterItemsToRender = useCallback(
        (item: IInstrumentScreenerView) => {
            let filterByRatio = true;
            let filterTicker = true;
            let filterMoexLevel = true;

            if (ticker) {
                filterTicker = item.ticker === ticker.ticker;
            }

            if (moexLevel) {
                filterMoexLevel = item.moexLevel.toString() === moexLevel;
            }

            if (ratioSelect) {
                switch (ratioSelect) {
                    case RatioOptions.High:
                        filterByRatio = !!item.ratio && item.ratio >= 100;
                        break;
                    case RatioOptions.Normal:
                        filterByRatio = !!item.ratio && item.ratio >= 50 && item.ratio < 100;
                        break;
                    case RatioOptions.Low:
                        filterByRatio = !!item.ratio && item.ratio < 50;
                        break;
                }
            }

            return filterByRatio && filterTicker && item.buyPriceRatio !== 0 && filterMoexLevel;
        },
        [ticker, ratioSelect, moexLevel]
    );

    const rows = items.filter(filterItemsToRender);

    return (
        <>
            <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                <InstrumentsAutocomplete onChange={handleTickerChange} />
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                <InputLabel id="demo-select-small-label">Рейтинг</InputLabel>
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={ratioSelect}
                    label="Рейтинг"
                    onChange={handleRatioChange}
                >
                    <MenuItem value={RatioOptions.None}>
                        <em>Все</em>
                    </MenuItem>
                    <MenuItem value={RatioOptions.High}>Высокий</MenuItem>
                    <MenuItem value={RatioOptions.Normal}>Средний</MenuItem>
                    <MenuItem value={RatioOptions.Low}>Низкий</MenuItem>
                </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                <InputLabel id="demo-select-small-label">Эшелон Мосбиржи</InputLabel>
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={moexLevel}
                    label="Эшелон Мосбиржи"
                    onChange={handleMoexLevelChange}
                >
                    <MenuItem value="">
                        <em>Все</em>
                    </MenuItem>
                    <MenuItem value={'1'}>1</MenuItem>
                    <MenuItem value={'2'}>2</MenuItem>
                    <MenuItem value={'3'}>3</MenuItem>
                    <MenuItem value={'0'}>Расписки</MenuItem>
                </Select>
            </FormControl>

            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 25 },
                    },
                    sorting: {
                        sortModel: [
                            {
                                field: 'buyPriceRatio',
                                sort: 'asc',
                            },
                        ],
                    },
                }}
                pageSizeOptions={[25, 50, 100]}
            />
        </>
    );
}
