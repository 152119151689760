import { useState, FormEvent } from 'react';
import { TextField, Link, CssBaseline, Button, Box, Container, Typography } from '@mui/material';

import { TG_BOT_REDIRECT_URL } from '@ystb/backend/dist/constants';

import { Copyright } from '../components/copyright';
import AppState from '../AppState';

export function SignInPage() {
    const [authError, setAuthError] = useState(false);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let username = (data.get('username') || '') as string;
        const otp = (data.get('otp') || '') as string;
        username = username.trim().replace('@', '');
        if (!username || !otp) {
            setAuthError(true);
            return;
        }
        AppState.restClient
            .login({ username, otp })
            .then((token) => {
                window.location.reload();
            })
            .catch((e) => {
                console.debug('Auth request failed with error: ', e);
                setAuthError(true);
            });
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    <img src="/img/logo.png" alt="logo" width={300} />
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Имя пользователя в Telegram"
                        name="username"
                        autoComplete="username"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="otp"
                        label="Одноразовый пароль"
                        type="password"
                        id="otp"
                        autoComplete="current-password"
                    />
                    <Link href={TG_BOT_REDIRECT_URL}>Получить пароль</Link>

                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                        Войти
                    </Button>
                    {authError && <Typography color={'error'}>Не верный логин или пароль</Typography>}
                </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
    );
}
