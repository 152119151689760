import { AccountType, UserRiskProfile, UserRole } from './user';

export function getHumanReadableRiskProfile(riskProfile: UserRiskProfile): string {
    switch (riskProfile) {
        case UserRiskProfile.Aggressive:
            return 'агрессивный';
        case UserRiskProfile.Conservative:
            return 'консервативный';
        case UserRiskProfile.Moderate:
            return 'умеренный';
        case UserRiskProfile.Undefined:
            return 'не задан'; // shouldn't be '-', because it's broke markdown at TG bot -> Settings
    }
}

export function getHumanReadableAccountType(accountType: AccountType): string {
    switch (accountType) {
        case AccountType.Investor:
            return 'инвестор';
        case AccountType.Speculative:
            return 'спекулянт';
        case AccountType.Trader:
            return 'трейдер';
        case AccountType.Undefined:
            return 'не задан';
    }
}

export function getHumanReadableUserRole(role: UserRole): string {
    switch (role) {
        case UserRole.Regular:
            return 'пользователь';
        case UserRole.Admin:
            return 'администратор';
        case UserRole.Moderator:
            return 'модератор';
        case UserRole.Undefined:
            return 'не зарегистрированный';
    }
}
